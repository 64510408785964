import { defineStore } from 'pinia';
import { useContext, ref } from '@nuxtjs/composition-api';
import * as Sentry from '@sentry/browser';

// RESPONSE TYPES

interface FinanceMetrics {
    net_cost_dollars: number;
    twenty_five_year_savings_dollars: number;
    break_even_years: string; // Can be "Immediate", "X.X", or "25+"
    monthly_savings_dollars: number;
    new_electricity_bill_dollars: number;
    monthly_financing_payment_dollars?: number;
}

interface LoanFinanceMetrics extends FinanceMetrics {
    monthly_financing_payment_dollars: number;
}

interface IncentiveOptions<T extends FinanceMetrics> {
    no_incentives: T;
    incentives: T;
}

interface SystemFinancingOptions {
    cash: IncentiveOptions<FinanceMetrics>;
    financing: IncentiveOptions<LoanFinanceMetrics>;
}

export interface EstimateResponse {
    electricity_offset_percent: number;
    estimate_log_id: number | null;
    monthly_electric_bill_dollars: number;
    solar_only: SystemFinancingOptions;
    solar_with_storage: SystemFinancingOptions;
}

// STORE TYPES

interface FinancingData {
    savings: number;
    financing: number;
    twentyFiveYearSavings: number;
    breakEvenYears: string;
    electricityOffset: number;
    newElectricityBill: number;
}

interface CashData {
    savings: number;
    twentyFiveYearSavings: number;
    breakEvenYears: string;
    electricityOffset: number;
    cashPrice: number;
    newElectricityBill: number;
}

interface CashObjects {
    cash: CashData;
    cashWithIncentives: CashData;
    cashWithStorage: CashData;
    cashWithStorageAndIncentives: CashData;
}

interface FinancingObjects {
    financing: FinancingData;
    financingWithIncentives: FinancingData;
    financingWithStorage: FinancingData;
    financingWithStorageAndIncentives: FinancingData;
}

export const useEstimateStore = defineStore('estimate', () => {
    const { $axios, $config } = useContext();
    const estimate = ref<EstimateResponse | null>(null);
    const loading = ref(false);

    const cashObjects = ref<CashObjects | null>(null);
    const financingObjects = ref<FinancingObjects | null>(null);

    function formatBreakEvenYears(years: string): string {
        if (years === 'Immediate') {
            return 'Immediate';
        }
        return `${years} yrs`;
    }

    function formatCashData(cashData: FinanceMetrics): CashData {
        return {
            savings: cashData.monthly_savings_dollars,
            twentyFiveYearSavings: cashData.twenty_five_year_savings_dollars,
            breakEvenYears: formatBreakEvenYears(cashData.break_even_years),
            electricityOffset: estimate.value?.electricity_offset_percent ?? 0,
            cashPrice: cashData.net_cost_dollars,
            newElectricityBill: cashData.new_electricity_bill_dollars,
        };
    }

    function formatFinancingData(financingData: LoanFinanceMetrics): FinancingData {
        return {
            savings: financingData.monthly_savings_dollars,
            financing: financingData.monthly_financing_payment_dollars,
            twentyFiveYearSavings: financingData.twenty_five_year_savings_dollars,
            breakEvenYears: formatBreakEvenYears(financingData.break_even_years),
            electricityOffset: estimate.value?.electricity_offset_percent ?? 0,
            newElectricityBill: financingData.new_electricity_bill_dollars,
        };
    }

    async function setEstimateData() {
        if (!estimate.value) {
            return;
        }

        cashObjects.value = {
            cash: formatCashData(estimate.value.solar_only.cash.no_incentives),
            cashWithIncentives: formatCashData(estimate.value.solar_only.cash.incentives),
            cashWithStorage: formatCashData(estimate.value.solar_with_storage.cash.no_incentives),
            cashWithStorageAndIncentives: formatCashData(estimate.value.solar_with_storage.cash.incentives),
        };
        financingObjects.value = {
            financing: formatFinancingData(estimate.value.solar_only.financing.no_incentives),
            financingWithIncentives: formatFinancingData(estimate.value.solar_only.financing.incentives),
            financingWithStorage: formatFinancingData(estimate.value.solar_with_storage.financing.no_incentives),
            financingWithStorageAndIncentives: formatFinancingData(
                estimate.value.solar_with_storage.financing.incentives,
            ),
        };
    }

    async function fetchEstimate(params: {
        zipCode: string;
        state: string;
        latitude: number;
        longitude: number;
        electricBill: number;
    }) {
        try {
            loading.value = true;
            // We check if we are in the development environment (localhost) and return prematurely
            // to avoid a call to es-site
            // TODO: IDR-475 - move this to Axios Interceptor.
            if (process.env.NODE_ENV === 'development') {
                return;
            }

            if (!params.zipCode || !params.state || !params.latitude || !params.longitude || !params.electricBill) {
                throw new Error('Missing required parameters');
            }
            const response = await $axios.get(`${$config.ONBOARDING_BFF_DOMAIN}/v1/compass-estimate`, {
                params: {
                    zip_code: params.zipCode,
                    state: params.state,
                    latitude: params.latitude,
                    longitude: params.longitude,
                    electric_bill: params.electricBill,
                },
            });
            if (response.status !== 200) {
                throw new Error('Failed to fetch solar estimate');
            }

            // Store initial response and format data for results
            estimate.value = response.data;
            setEstimateData();
        } catch (error) {
            Sentry.captureMessage(`Solar estimate endpoint failed with error: ${error}`, 'error');
            throw error;
        } finally {
            loading.value = false;
        }
    }

    return {
        estimate,
        loading,
        financingObjects,
        cashObjects,

        fetchEstimate,
        formatBreakEvenYears,
        formatCashData,
        formatFinancingData,
    };
});
